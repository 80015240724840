import React, { Fragment, useState } from "react";
import "./register-tabs.css"

const TabItem = ({ children, isActive, title, subtitle,onSelectTab }) => {

    return <Fragment>
        <div className={"tab-mob-head "+ (isActive ? "active" : "")}>
            <button type="button" onClick={onSelectTab}>
                <span className="title">{title}</span>
                <span className="subtitle">{subtitle}</span>
            </button>
        </div>
        <div className={"tab-content " + (isActive ? "active" : "")}>
            {children}
        </div>
    </Fragment>
}
const RegisterTab = ({ children, onPrev, onNext, onFinish, onSelectTab,busy }) => {

    const [active, setActive] = useState(0);

    return <div className="register-tabs">
        <ul className="menu">
            {children && children.map((child, key) => {
                return <li key={key} className={active === key ? "active" : ""}><button type="button" onClick={async () => {

                    var selected = await onSelectTab(key);
                    if (selected === true) {
                        setActive(key);
                    }
                }}>
                    <span className="title">{child.props.title}</span>
                    <span className="subtitle">{child.props.subtitle}</span>
                </button></li>
            })}
        </ul>

        <div className="tab-contents">
            {children && children.map((child, key) => {
                return <TabItem key={key} onSelectTab={async () => {

                    var selected = await onSelectTab(key);
                    if (selected === true) {
                        setActive(key);
                    }
                }} index={key} title={child.props.title} setActive={(index)=>{
                    setActive(index);
                }} subtitle={child.props.subtitle} isActive={active === key}>
                    {child.props.children}
                </TabItem>
            })}
            <div className="buttons">
                {active > 0 && <button type="button" onClick={() => {
                    onPrev(active);
                    setActive(active - 1)
                }} className="btn-primary">Previous</button>}
                <button disabled={busy} type="button" onClick={async () => {
                    if (active === (children.length - 1)) {
                        onFinish(active);
                        return;
                    }
                    var n = await onNext(active);
                    if (n === true) {
                        setActive(active + 1);
                    }
                }} className="btn-primary">{active === (children.length - 1) ? "Finish" : "Next"}</button>
            </div>
        </div>
    </div>
}

export default RegisterTab