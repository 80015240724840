import "../../styles/application-sections.css"

import React, {
    useEffect,
    useMemo,
    useReducer,
    useState,
} from "react"

import axios from "axios"
import { navigate } from "gatsby"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { useForm } from "react-hook-form"
import ReactInputMask from "react-input-mask"

import { API_BASE_URL } from "../../constants/config"
import {
    emailFormat,
    phoneFormat,
    phoneFormatField,
    phoneTypes,
    zipFormat,
    zipFormatField,
} from "../../constants/defaults"
import { parseError } from "../../constants/utility"
import {
    alertInitialState,
    alertReducer,
} from "../../reducers/alert-reducer"
import Alert from "../shared/Alert"
import InlineLoader from "../shared/inline-loader"
import RegisterTab from "../shared/register-tabs"

const Section1 = () => {

    return <div className="app-page-sec">
        <div className="content">
            <p>Please submit this form if you are interested in volunteering for DFWLRRC.</p>
            <p><strong>VERY IMPORTANT</strong>: Please do not use this form as as a request to surrender a Labrador to DFWLRRC – <strong>your request will not be considered</strong>. If you are interested in DFWLRRC considering a Labrador for our rescue program, please follow the instructions provided here.</p>
        </div>
    </div>
}

const Section2 = () => {
    const [states, setStates] = useState([]);

    const [busy, setBusy] = useState(false);
    const [alertState, dispatchAlert] = useReducer(alertReducer, alertInitialState);

    const { register, formState: { errors }, watch, trigger, getValues, setValue } = useForm();

    const { executeRecaptcha } = useGoogleReCaptcha();

    const personal_info_fields = useMemo(() => ([
        { label: "First Name", key: "first_name", type: "text", required: true, className: 'half' },
        { label: "Last Name", key: "last_name", type: "text", required: true, className: 'half' },
        { label: "Spouse's First Name", key: "spouse_first_name", type: "text", required: false, className: 'half' },
        { label: "Spouse's Last Name", key: "spouse_last_name", type: "text", required: false, className: 'half' },
        { label: "Street Address", key: "street_address", type: "text", required: true, className: 'w-full' },
        { label: "City", key: "city", type: "text", required: true, className: 'one-third' },
        { label: "State", key: "state", type: "select", required: true, className: 'one-third', data_source: states, placeholder: true },
        { label: "Zipcode", key: "zipcode", type: "zip", pattern: zipFormat, required: true, className: 'one-third' },
        { label: "Primary Phone", key: "primary_phone_type", type: "select", required: true, className: 'half', data_source: phoneTypes },
        { label: "", pattern: phoneFormat, key: "primary_phone", type: "phone", required: true, className: 'half' },
        { label: "Alternative Phone", key: "alternative_phone_type", type: "select", required: false, className: 'half', data_source: phoneTypes },
        { label: "", pattern: phoneFormat, key: "alternative_phone", type: "phone", required: false, className: 'half' },

        { label: "Email Address", pattern: emailFormat, key: "email", type: "email", required: true, className: 'half', desc: "We communicate primarily through email" },
    ]), [states]);


    const questions_info_fields = useMemo(() => ([
        {
            label: "How did you hear about us?", key: "how_did_you_hear", type: "select", required: true, className: 'col-span-2 md:col-span-1', data_source: [
                { name: "Newsletter" },
                { name: "Newspaper" },
                { name: "Internet Search" },
                { name: "Event" },
                { name: "Referral" },
                { name: "Website" },
                { name: "Other (please specify)", key: "other" },
            ],
        }, {
            label: "I am interested in learning more about (check all that apply)", key: "interested_learning",
            type: "checkbox", required: true, className: 'col-span-2', data_source: [
                { name: "Fostering" },
                { name: "Helping with showings" },
                { name: "Helping with fundraising" },
                { name: "Help with events" },
                { name: "Transporting dogs" },
                { name: "Helping with intake of dogs" },
                { name: "Helping with applications", },
                { name: "Would like more info on volunteer options", },
            ],
        },

    ]), [])

    const watch_how_did_you_hear = watch('how_did_you_hear');


    useEffect(() => {
        var m = true;
        if (m) {
            axios.get(API_BASE_URL + "/static/states").then(({ data }) => {
                setStates(data.data);
            })


        }
        return () => {
            m = false;
        }
    }, [])
    const onSubmit = async (data) => {
        if (busy) {
            return;
        }
        if (!executeRecaptcha) {

            return;
        }
        setBusy(true);
        dispatchAlert({ type: 'reset' });

        return new Promise((resolve) => {
            setTimeout(async () => {
                const token = await executeRecaptcha('volunteer')
                data = { ...data, token: token }
                axios.post(API_BASE_URL + "/website/application/volunteer", data).then(({ data }) => {
                    setBusy(false);
                    navigate("/thank-you-volunteer-application");
                }).catch(({ response }) => {
                    setBusy(false);
                    if (response) {
                        var { data } = response;
                        window.scroll({ top: document.querySelector(".app-page-sec").offsetTop, behavior: 'smooth' })
                        dispatchAlert({ type: 'error', text: parseError(data) })

                    }
                })

                resolve()
            }, 3000);
        });


    }
    const validateField = async () => {

        var fields = []

        personal_info_fields.forEach((item, key) => {
            fields.push(item.key);
        })
        const validate = await trigger(fields);
        window.scroll({ top: document.querySelector(".app-page-sec").offsetTop, behavior: 'smooth' })
        return validate;
    }

    useEffect(() => {
        //debug
        var m = true;
        if (m) {
            setTimeout(() => {
                setValue("state", "TX");
            }, 1000)
            
            // setValue("first_name", "Elon");
            // setValue("last_name", "Musk");
            // setValue("spouse_first_name", "Jane");
            // setValue("spouse_last_name", "Doe");
            // setValue("street_address", "Wall Street");
            // setValue("city", "Phoenix");
           
            // setValue("state", "AZ");
            // setValue("zipcode", "85006");
            // setValue("primary_phone_type", "Mobile");
            // setValue("primary_phone", "(502) 332-3232");
            // setValue("alternative_phone_type", "Work");
            // setValue("alternative_phone", "(502) 333-4444");
            // setValue("email", "natmbndev2@gmail.com");
            // setValue("how_did_you_hear", "Newspaper");
            // setValue("interested_learning", ["Fostering","Helping with fundraising"]);
        }
        return () => {
            m = false;
        }
    }, [setValue])


    return <div className="app-page-sec">
        <div className="content">
            <Alert data={alertState} />
            <form>
                <RegisterTab
                busy={busy}
                    onSelectTab={async (index) => {
                        return await validateField();
                    }}
                    onPrev={() => {
                        window.scroll({ top: document.querySelector(".app-page-sec").offsetTop, behavior: 'smooth' })
                    }}
                    onNext={async (index) => {
                        return await validateField();
                    }} onFinish={async (index) => {
                        var validate = await trigger();
                        if (validate) {
                            onSubmit(getValues())
                        }
                    }}>
                    <div title="Contact Information">
                        <h3>Contact Information</h3>
                        <div className="form-fields form-flex">
                            {personal_info_fields && personal_info_fields.map((item, key) => {
                                return <div key={"l" + key} className={`field-group ${item.className}`}>
                                    <label className={item.label === "" ? 'empty-label' : ''}>{item.label !== "" ? item.label : <span>&nbsp;</span>} {item.required === true && item.label !== "" && <span className="req">*</span>}</label>
                                    {item.type === 'select' && <select {...register(item.key, { required: item.required })}>
                                        {item.placeholder === true && <option value="">Select</option>}
                                        {item.data_source && item.data_source.map((s, k) => {
                                            return <option key={"c" + k} value={s.key}>{s.name}</option>;
                                        })}
                                    </select>}
                                    {['text', 'number'].indexOf(item.type) > -1 && <input type={item.type} {...register(item.key, { required: item.required })} />}
                                    {item.type === 'email' && <input type={item.type} {...register(item.key, { required: item.required, pattern: item.pattern })} />}
                                    {item.type === 'phone' && <ReactInputMask type={"text"} {...register(item.key, { required: item.required, pattern: item.pattern })} mask={phoneFormatField} />}
                                    {item.type === 'zip' && <ReactInputMask type={"text"} {...register(item.key, { required: item.required, pattern: item.pattern })} mask={zipFormatField} />}
                                    {item.desc && <span className="desc">{item.desc}</span>}
                                    {errors[item.key] && errors[item.key].type === 'required' && <span className="text-red-600">This field is required</span>}
                                    {errors[item.key] && errors[item.key].type === 'pattern' && item.type === 'email' && <span className="text-red-600">Invalid Email Format</span>}
                                    {errors[item.key] && errors[item.key].type === 'pattern' && item.type === 'phone' && <span className="text-red-600">Invalid Phone Format</span>}
                                    {errors[item.key] && errors[item.key].type === 'pattern' && item.type === 'zip' && <span className="text-red-600">Invalid Zip Format</span>}
                                </div>
                            })}
                        </div>
                    </div>
                    <div title="Questions">
                        <h3>Questions</h3>
                        <div className="form-fields">
                            {questions_info_fields && questions_info_fields.map((item, key) => {
                                return <div key={key} className={`field-group ${item.className}`}>
                                    <label>{item.label} {item.required === true && <span className="req">*</span>}</label>
                                    {item.type === 'select' && <select {...register(item.key, { required: item.required })}>
                                        <option value="">Select</option>
                                        {item.data_source && item.data_source.map((s, k) => {
                                            return <option key={k} value={s.key ? s.key : s.name}>{s.name}</option>;
                                        })}
                                    </select>}
                                    {item.type === 'checkbox' && <ul>{item.data_source.map((s, k) => {
                                        return <li key={k}><label><input type="checkbox" {...register(item.key, { required: item.required })} value={s.name} /> {s.name}</label></li>
                                    })}</ul>}
                                    {watch_how_did_you_hear === 'other' && item.key === 'how_did_you_hear' && <div className="field-group mt-1 col-span-2">
                                        <input type="text" {...register('how_did_you_hear_other', { required: true })} />
                                    </div>}
                                    {item.desc && <span className="desc">{item.desc}</span>}
                                    {errors[item.key] && errors[item.key].type === 'required' && <span className="text-red-600">This field is required</span>}
                                    {errors.how_did_you_hear_other && <span className="text-red-600">This field is required</span>}
                                </div>
                            })}

                        </div>
                    </div>
                </RegisterTab>
            </form>
            <InlineLoader show={busy} />
        </div>
    </div>
}

const Sections = {
    Section1,
    Section2
}

export default Sections