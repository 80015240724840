import React, { useState } from "react"
import imgloader from "../../images/inline-loader.svg"
import { CSSTransition } from "react-transition-group"
const InlineLoader = ({ show,onClose }) => {
    const [showLoader, setShowLoader] = useState(false)
    return <CSSTransition in={show} timeout={500} classNames="fade" unmountOnExit onEntered={() => { setShowLoader(true) }}>
        <div className="fixed flex items-center justify-center z-50 min-w-full max-w-full min-h-screen top-0 left-0 bg-gray-700 bg-opacity-50 backdrop-filter backdrop-blur-sm">
            <CSSTransition in={showLoader} timeout={500} classNames="zoomIn" unmountOnExit>
                <span className="rounded-full w-20 h-20 bg-white shadow-lg">
                    <img src={imgloader} alt="Loading" />
                </span>
            </CSSTransition>
        </div>

    </CSSTransition>
}

export default InlineLoader