import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import InnerBanner from "../components/shared/inner-banner"
import Sections from "../components/volunteer-appliaction/sections"
import banner from "../images/volunteer-banner-bg.jpg"

const VolunteerApplicationPage = () => {

    return <Layout colored_header={true}>
        <Seo title="Volunteer Application" />
        <InnerBanner theme="white" image={banner} title="Volunteer Application" />
        <Sections.Section1/>
        <Sections.Section2/>
    </Layout>
}

export default VolunteerApplicationPage