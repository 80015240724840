const alertInitialState = {
    error: false,
    text: ""
}
const alertReducer = (state, action) => {

    switch (action.type) {
        case 'success':
            return { error: false, text: action.text };
        case 'error':
            return { error: true, text: action.text };
        case 'reset':
            return alertInitialState;
        default:
            throw new Error();

    }
}

export {
    alertReducer,
    alertInitialState
}